import React from 'react';
import { mapModifiers, ModifierProp } from 'libs/component';

export const ICON_SHAPES = [
  'arrow-left-large',
  'arrow-right',
  'arrow-right-large',
  'close',
  'chevron-down',
  'chevron-up',
  'chevron-right',
  'ellipsis',
  'facebook',
  'gear',
  'jigsaw',
  'messenger',
  'replay',
  'search',
  'skip',
  'telegram',
  'twitter',
  'whatsapp',
];
export const ICON_COLORS = ['white', 'dark-navy', 'red'];

export type IconShape = typeof ICON_SHAPES[number];
export type IconColor = typeof ICON_COLORS[number];

export interface IconProps {
  name: typeof ICON_SHAPES[number];
  color?: typeof ICON_COLORS[number];
  modifiers?: ModifierProp<'text-prefix'>;
}

export const Icon: React.FC<IconProps> = ({ name, color, modifiers }) => (
  <i className={mapModifiers('a-icon', modifiers, name, color)} />
);
