import React from 'react';
import { mapModifiers, ModifierProp } from 'libs/component';

export interface ContainerProps {
  children?: React.ReactNode;
  modifiers?: ModifierProp<'gold' | 'blue' | 'ivory'>;
}

export const Container: React.FC<ContainerProps> = ({ children, modifiers }) => (
  <div className={mapModifiers('o-container', modifiers)}>{children}</div>
);
