import React from 'react';
import { mapModifiers } from 'libs/component';

export interface ColProps {
  children?: React.ReactNode;
  width?: number;
  widthSP?: number;
  className?: string;
}

export const Col: React.FC<ColProps> = ({
  children,
  width = null,
  widthSP = null,
  className: additionalClassName = null,
}) => {
  const elementClassName = [
    mapModifiers('o-col', width?.toString(), widthSP ? `sp-${widthSP}` : null),
    additionalClassName,
  ]
    .join(' ')
    .trim();
  return <div className={elementClassName}>{children}</div>;
};
