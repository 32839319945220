/* eslint-disable no-control-regex, @typescript-eslint/no-explicit-any */
export const EMAIL_REGEXP =
  /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/gi;
export const BASE_URL = process.env.BASE_URL;
export const SITE_BASE_PATH = process.env.SITE_BASE_PATH || '';
export const CONTACT_EMAIL = 'v-passer@kits.nttdata.co.jp';
export const COOKIE_NAME = 'vpasser_site';
export const FB_APP_ID = '395455832261492';
export const GTM_ID = 'GTM-KX8QJR5';
export const SP_WIDTH = 768;
// export const EASING_VALUE = [0,.77,.26,.26];
// export const EASING_VALUE = [0,1,0,.56];
export const EASING_VALUE = [0.17, 1, 0.31, 0.72];
export const SEO_INFO = {
  HOME: {
    title: 'v-PaSSer - Payment and Settlement Services',
    description: `With our abundant experiences in financial IT infrastructure, v-PaSSer assists you in achieving your goals with customization-based system and extraordinary support.`,
  },
  ABOUT: {
    title: 'About',
    description: `v-PaSSer offers a wide range of payment & settlement solutions around the world. Our technology helps clients growth in global community and adaptation to ever-increasing complexity of settlement system.`,
  },
  SERVICE_LIST: {
    title: 'Service List',
    description: `Not only provide products, but also fully support our clients throughout Discovery, Design, Develop and Drive to maximize their value.`,
  },
  PRODUCT_LIST: {
    title: 'Product List',
    description: `Best combination of cutting edge products & finely tuned  customization can be delivered flexibly & expeditiously for match customer requirement & harmonization with existing system.`,
  },
  PRODUCT_LIST_RTGS: {
    title: 'Real Time Gross Settlement (RTGS)',
    description: `v-PaSSer RTGS provide reliable settlement excluding settlement risk, and efficient settlement with Liquidity saving between Financial institution at Central bank account.`,
  },
  PRODUCT_LIST_RTP: {
    title: 'Real-Time Payments System (RTP)',
    description: `v-PaSSer RTP realize retail payment between customer accounts with emphasis on real-time payment & 24/7 availability, and risk-free settlement using debit cap features.`,
  },
  PRODUCT_LIST_CSD: {
    title: 'Central Securities Depository (CSD)',
    description: `v-PaSSer CSD develop bond market by secure and efficient issuance/settlement management with database, and also entire financial market by supplying funds with bond collateral.`,
  },
  PRODUCT_LIST_ACH_BP: {
    title: 'ACH Bulk Payment (ACH-BP)',
    description: `v-PaSSer ACH-BP provide 24/7 low-value payment for Bulk transaction such as salary, bill and tax payment, and risk-free settlement using debit cap features.`,
  },
  PRODUCT_LIST_CTS: {
    title: 'Cheque Trancation System (CTS)',
    description: `CTS, digital-based cheque exchange system, realizes safe, efficient, and speedy cheque settlement by the electronic transferring of cheque image.`,
  },
  PRODUCT_LIST_GATEWAY: {
    title: 'Gateway',
    description: `v-PaSSer Gateway is compatible with a wide variety of protocols & file formats, and many useful functions for Reconcile. It covers both gateway of Central bank & Financial Institutions.`,
  },
  PRODUCT_LIST_DASHBOARD: {
    title: 'Dashboard',
    description: `v-PaSSer Dashboard realize risk & liquidity management with at-a-glance & user-friendly graphical interface, and alert function to support operations without mistakes/omissions.`,
  },
  PRODUCT_LIST_NON_FUNCTIONAL: {
    title: 'Non-functional specification',
    description: `v-PaSSer promises the safe, stable, and secure system by ensuring that the non-functional requirements of advanced payment & settlement system are implemented.`,
  },
  CASE_STUDY_ASEAN: {
    title: 'Case Study - An ASEAN Country',
    description: `The implementation of “v-PaSSer” has promoted to advanced digital payment and settlement, made formal remittance services more accessible. It also contributes to the financial inclusion.`,
  },
  CONTACT_US: {
    title: 'Contact Us',
    description: `Contact us if you have questions or need assistance. We will gladly provide you with all the necessary information and support.`,
  },
  TERMS_AND_CONDITIONS: {
    title: 'Terms & Conditions',
    description: `These Terms & Conditions applies to (Site URL). Please read these Terms & Conditions carefully before you start to use our Site, as these will apply to your use of our Site. By using our Site, you confirm that you accept these Terms & Conditions and that you agree to comply with them. If you do not agree to these Terms & Conditions, please refrain from using our Site.`,
  },
  PRIVACY_POLICY: {
    title: 'Privacy Policy',
    description: `v-PaSSer (“we”, “our”, or “us”)  recognizes the importance of personal information and ensures the protection and safeguarding of its customers’ personal information as a fundamental principle of its business and its responsibility to society.`,
  },
};
export const PRODUCTS_LIST_INFO = [
  {
    slug: 'rtgs',
    title: 'Real Time Gross Settlement (RTGS)',
  },
  {
    slug: 'rtp',
    title: 'Real-Time Payments System (RTP)',
  },
  {
    slug: 'csd',
    title: 'Central Securities Depository (CSD)',
  },
  {
    slug: 'ach-bp',
    title: 'ACH Bulk Payment (ACH-BP)',
  },
  {
    slug: 'cts',
    title: 'Cheque Trancation System (CTS)',
  },
  {
    slug: 'gateway',
    title: 'Gateway',
  },
  {
    slug: 'dashboard',
    title: 'Dashboard',
  },
  {
    slug: 'non-functional',
    title: 'Non-functional specification',
  },
];
export const CASE_STUDY_VARIANTS: any = {
  root: {
    enter: {
      transition: {},
    },
    exit: {
      transition: { staggerChildren: 0.05, staggerDirection: -1 },
    },
  },
  heading: {
    enter: {
      opacity: 1,
      y: 0,
      transition: {
        duration: 1.5,
        ease: EASING_VALUE,
        delay: 1.5,
      },
    },
    exit: {
      opacity: 0,
      y: 10,
    },
  },
  image: {
    enter: {
      opacity: 1,
      y: 0,
      transition: {
        duration: 0.5,
      },
    },
    exit: {
      opacity: 0,
      y: 0,
    },
  },
  imageText: {
    enter: {
      opacity: 1,
      y: 0,
      transition: {
        duration: 1.5,
        ease: EASING_VALUE,
        delay: 1.5,
      },
    },
    exit: {
      opacity: 0,
      y: 10,
    },
  },
  content: {
    enter: {
      transition: {
        staggerChildren: 0.25,
      },
    },
    exit: {
      transition: { staggerChildren: 0.05, staggerDirection: -1 },
    },
  },
  text: {
    enter: {
      opacity: 1,
      y: 0,
      transition: {
        delay: 1.5,
        duration: 1.5,
        ease: EASING_VALUE,
      },
    },
    exit: {
      opacity: 0,
      y: 10,
    },
  },
  cta: {
    enter: {
      opacity: 1,
      y: 0,
      transition: {
        delay: 1.75,
        duration: 1.5,
        ease: EASING_VALUE,
      },
    },
    exit: {
      opacity: 0,
      y: 10,
    },
  },
};

export const CASE_STUDY_VARIANTS_SP: any = {
  root: {
    enter: {
      transition: {},
    },
    exit: {
      transition: { staggerChildren: 0.05, staggerDirection: -1 },
    },
  },
  heading: {
    enter: {
      opacity: 1,
      y: 0,
      transition: {
        duration: 1.5,
        ease: EASING_VALUE,
        delay: 0.5,
      },
    },
    exit: {
      opacity: 0,
      y: 10,
    },
  },
  image: {
    enter: {
      opacity: 1,
      y: 0,
      transition: {
        duration: 0.5,
      },
    },
    exit: {
      opacity: 0,
      y: 0,
    },
  },
  imageText: {
    enter: {
      opacity: 1,
      y: 0,
      transition: {
        duration: 1.5,
        ease: EASING_VALUE,
        delay: 0.5,
      },
    },
    exit: {
      opacity: 0,
      y: 10,
    },
  },
  content: {
    enter: {
      transition: {
        staggerChildren: 0.25,
      },
    },
    exit: {
      transition: { staggerChildren: 0.05, staggerDirection: -1 },
    },
  },
  text: {
    enter: {
      opacity: 1,
      y: 0,
      transition: {
        delay: 0.5,
        duration: 1.5,
        ease: EASING_VALUE,
      },
    },
    exit: {
      opacity: 0,
      y: 10,
    },
  },
  cta: {
    enter: {
      opacity: 1,
      y: 0,
      transition: {
        delay: 0.75,
        duration: 1.5,
        ease: EASING_VALUE,
      },
    },
    exit: {
      opacity: 0,
      y: 10,
    },
  },
};
