import React from 'react';
import { mapModifiers, ModifierProp } from 'libs/component';

export interface RowProps {
  children?: React.ReactNode;
  modifiers?: ModifierProp<'gold' | 'blue' | 'ivory'>;
}

export const Row: React.FC<RowProps> = ({ children, modifiers }) => (
  <div className={mapModifiers('o-row', modifiers)}>{children}</div>
);
